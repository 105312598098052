export const fileTypes = [
  {
    id: 0,
    name: 'PDF',
  },
  {
    id: 1,
    name: 'TIFF',
  },
  {
    id: 2,
    name: 'PS',
  },
  {
    id: 3,
    name: 'EPL',
  },
  {
    id: 4,
    name: 'ZPL',
  },
];
