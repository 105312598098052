export const Parcel = {
  clientEntityState: 4,
  id: '',
  tenantId: '',
  content: '',
  length: '',
  width: '',
  height: '',
  weight: '',
  customerData1: '',
  customerData2: '',
  customerData3: '',
};
