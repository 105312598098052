export const payerTypes = [
  {
    id: 0,
    name: 'Odbiorca',
    value: 'RECEIVER',
  },
  {
    id: 1,
    name: 'Nadawca',
    value: 'SENDER',
  },
  {
    id: 2,
    name: 'Strona trzecia',
    value: 'THIRD_PARTY',
  },
];
