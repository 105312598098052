export const types = [
  {
    id: 0,
    name: 'BIC3',
  },
  {
    id: 1,
    name: 'EXTENDED',
  },
];
