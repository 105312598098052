export const dpdAdditionalServices = [
  {
    id: 1,
    serviceId: 'COD',
    name: 'Pobranie',
    description: 'Zwrot pobrania',
    parameters: true,
    remark: '',
  },
  {
    id: 2,
    serviceId: 'INSURANCE',
    name: 'Ubezpieczenie',
    description: 'Ubezpieczenie przesyłki',
    parameters: true,
    remark: '',
  },
  {
    id: 4,
    serviceId: 'ROD',
    name: 'Zwrot dokumentów',
    description: '',
    parameters: false,
    remark: '',
  },
  {
    id: 100,
    serviceId: 'HOUR_DELIVERY',
    name: 'Dostawa na godzinę',
    description: '',
    parameters: true,
    remark: '',
  },
  {
    id: 102,
    serviceId: 'DELIVERY_UP_TO_9_30',
    name: 'Doręczenie na 9:30',
    description: '',
    parameters: false,
    remark: '',
  },
  {
    id: 105,
    serviceId: 'DELIVERY_UP_TO_12',
    name: 'Doręczenie do 12:00',
    description: '',
    parameters: false,
    remark: '',
  },
  {
    id: 115,
    serviceId: 'SATURDAY_DELIVERY',
    name: 'Dostawa w sobotę',
    description: '',
    parameters: false,
    remark: '',
  },
  {
    id: 118,
    serviceId: 'DELIVER_IN_PERSON',
    name: 'Doręczenie do rąk własnych',
    description: '',
    parameters: false,
    remark: '',
  },
  {
    id: 122,
    serviceId: 'NEXTDAY_DELIVERY',
    name: 'Doręczenie na następny dzień',
    description: '',
    parameters: false,
    remark: '',
  },
  {
    id: 123,
    serviceId: 'TODAY_DELIVERY',
    name: 'Doręczenie w dniu nadania',
    description: '',
    parameters: false,
    remark: '',
  },
  {
    id: 701,
    serviceId: 'THIRD_PARTY_PAYER',
    name: 'Płatnik',
    description: '',
    parameters: false,
    remark: '',
  },
];
