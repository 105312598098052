import { DpdDefaultValues } from './DpdDefaultValues';
import { LabelSettings } from './LabelSettings';

export const DPD = {
  clientEntityState: 4,
  id: '',
  tenantId: '',
  clientId: '',
  configurationName: '',
  password: '',
  courier: '',
  courierCode: 'dpd',
  integration: 'DPD',
  login: '',
  masterFid: 0,
  urlAddress: '',
  payerType: 'RECEIVER',
  labelSettings: LabelSettings,
  dpdDefaultValues: DpdDefaultValues,
  additionalServices: { services: [] },
  fieldsMappings: { mappings: [] },
};
