import { Parcel } from './Parcel';
import { CashOnDelivery } from './CashOnDelivery';

export const DpdDefaultValues = {
  id: '',
  clientId: '',
  referenceLine1: '',
  referenceLine2: '',
  referenceLine3: '',
  isUsingReturnOfDocuments: false,
  parcel: Parcel,
  cashOnDelivery: CashOnDelivery,
};
