export const formats = [
  {
    id: 0,
    name: 'A4',
    value: 'A4',
  },
  {
    id: 1,
    name: 'Etykieta',
    value: 'LBL_PRINTER',
  },
];
