<template>
  <configuration-set
    v-if="!!getConfigurationSet()?.courier"
    :components="components"
    :speditor="speditor"
    :current-component="currentComponent"
    :loading="loading"
    @@changeComponent="changeComponent"
    @@save="save"
  >
    <template v-slot:content>
      <ServiceAuthorization
        v-show="currentComponent === 'ServiceAuthorization'"
        :is-new="isNew"
      />

      <AdditionalServices
        v-show="currentComponent === 'AdditionalServices'"
        :is-new="isNew"
      />

      <AdditionalFields
        v-show="currentComponent === 'AdditionalFields'"
        :is-new="isNew"
      />

      <LabelSettings
        v-show="currentComponent === 'LabelSettings'"
        :is-new="isNew"
      />

      <Parcel
        v-show="currentComponent === 'Parcel'"
        :is-new="isNew"
      />

      <CashOnDelivery
        v-show="currentComponent === 'CashOnDelivery'"
        :is-new="isNew"
      />
    </template>
  </configuration-set>
</template>

<script>
import { DPD } from '@/constants/Speditors/DPD/configuration/DPD';
import CashOnDelivery from '@/views/Speditors/DPD/Forms/CashOnDelivery.vue';
import Parcel from '@/views/Speditors/DPD/Forms/Parcel.vue';
import LabelSettings from '@/views/Speditors/DPD/Forms/LabelSettings.vue';
import ServiceAuthorization from '@/views/Speditors/DPD/Forms/ServiceAuthorization.vue';
import ConfigurationSet from '@/components/shared/ConfigurationSet.vue';
import configurationMixin from '@/mixins/configurationMixin';
import AdditionalServices from './Forms/AdditionalServices.vue';
import AdditionalFields from './Forms/AdditionalFields.vue';

export default {
  components: {
    ServiceAuthorization,
    LabelSettings,
    Parcel,
    CashOnDelivery,
    ConfigurationSet,
    AdditionalServices,
    AdditionalFields,
  },
  mixins: [configurationMixin],
  data: () => ({
    speditor: 'DPD',
    currentComponent: 'ServiceAuthorization',
    components: [
      { code: 'ServiceAuthorization', name: 'courierSet.serviceAuthorizationName' },
      { code: 'AdditionalServices', name: 'courierSet.additionalServices' },
      { code: 'LabelSettings', name: 'courierSet.labelSettingsName' },
      { code: 'Parcel', name: 'courierSet.parcelName' },
      { code: 'CashOnDelivery', name: 'courierSet.cashOnDeliveryName' },
      { code: 'AdditionalFields', name: 'courierSet.additionalFields' },
    ],
  }),
  methods: {
    setNewConfiguration() {
      this.setConfigSet({ response: DPD });
    },
  },
};
</script>
